import moment from 'moment'


const getYear = (value) => {
  if (value == null || value == undefined || value === 0) {
    return '';
  } else {
    return moment(value).year();
  }

}
const getYears = (aa, items, i) => {
  if (items.length > (i + 1)) {
    let a = moment(aa).year();
    let b = moment(items[i].createDate).year();
    if (a != b) {
      return b;
    }
  }

  return '';
}


const formatTimer = (value) => {
  if (value == null || value == undefined || value === 0) {
    return '';
  } else {
    return moment(value).format('YYYY/MM/DD HH:mm:ss')
  }

}
const formatTimer2 = (value) => {
  if (value == null || value == undefined || value === 0) {
    return '';
  } else {
    return moment(value).format('HH:mm YYYY/MM/DD ')
  }

}
const formatDay1 = (value) => {
  if (value == null || value == undefined) {
    return '';
  } else {
    return moment(value).format('YYYY年MM月DD日')
  }
}
const formatDay = (value) => {
  if (value == null || value == undefined) {
    return '';
  } else {
    return moment(value).format('YYYY/MM/DD')
  }
}

const formatDate = (value) => {
  if (value == null || value == undefined) {
    return '';
  } else {
    return moment(value).format('MM/DD')
  }

}
const formatHours = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  return moment(value).format('HH:mm')


}
const formatDate2 = (value) => {
  if (value == null || value == undefined) {
    return '';
  } else {
    return moment(value).format('MM/DD HH:mm')
  }

}
const formatDate3 = (value) => {
  if (value == null || value == undefined) {
    return '';
  } else {
    return moment(value).format('DD')
  }

}

const dayDiff = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  //获取当前时间
  let m1 = moment();
  //获取需要对比的时间
  let m2 = moment(value);
  //计算相差多少天 day可以是second minute
  if (m2.diff(m1, 'day') < 0) {
    return 0;
  }
  return m2.diff(m1, 'day');
}


const compareDate = (timestamp, day = 0) => {
  // timestamp 为要传入的时间戳
  // day 为要减去的日子 因为比较*当天*的话，是不需要减的，所以默认定义成0,

  // 根据上面分析，有些需要参数`time`，有些不需要，所以使用一个函数来区分一下
  let newDate = (time = null) => {
    return time === null ? new Date() : new Date(time)
  }

  // 这里返回 比较后的值，比较成功，则返回`true`，失败则返回`false`
  return (newDate(timestamp).getDate() == newDate().getDate() - day) && (newDate(timestamp).getMonth() == newDate().getMonth()) && (newDate(timestamp).getYear() == newDate().getYear())
}


export {
  formatTimer,
  formatTimer2,
  formatHours,
  formatDay,
  formatDate,
  formatDay1,
  formatDate2,
  dayDiff,
  getYear,
  getYears,
  formatDate3,
  compareDate
}
