import axios from 'axios'
import moment from 'moment'
import store from '../store/index'

moment.locale('zh-cn')
import db from './localstorage.js'
import m from './message'

//统一配置
let ANIMAL_REQUEST = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  //return 'https://app.yshinfo.com'
  // baseURL: 'http://192.168.1.13:9876',
  // baseURL: 'http://192.168.1.18:9876',
  // baseURL: 'http://192.168.1.15:9876',
  responseType: 'json',
})


//拦截请求
ANIMAL_REQUEST.interceptors.request.use((config) => {
  let expireTime = store.state.account.expireTime
  let now = moment().format('YYYYMMDDHHmmss')
  // 让token早10秒种过期，提升“请重新登录”弹窗体验
  if (now - expireTime >= -10) {
    m.error("登录已过期，请重新登录");
    db.clear()
    location.reload()
    return;
  }
  // 有 token就带上
  if (store.state.account.token) {
    config.headers.Authentication = store.state.account.token
  }
  return config
}, (error) => {
  m.error(error + '未知错误!');
})

const request = {
  post(url, params) {
    return ANIMAL_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        // 'Content-Type':'application/json; charset=UTF-8'
      }
    })
  },
  postJson(url, params) {
    return ANIMAL_REQUEST.post(url, params, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
  },
  put(url, params) {
    return ANIMAL_REQUEST.put(url, params, {
      transformRequest: [(params) => {
        let result = ''
        if (params != null) {
          Object.keys(params).forEach((key) => {
            if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
              result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
            }
          })
        }
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  putJson(url, params) {
    return ANIMAL_REQUEST.put(url, params, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
  },
  get(url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (let key in params) {
        // params.hasOwnProperty(key)

        if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return ANIMAL_REQUEST.get(`${url}${_params}`)
  },
  delete(url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (let key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return ANIMAL_REQUEST.delete(`${url}${_params}`)
  },
  upload(url, params) {
    return ANIMAL_REQUEST.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

  },
  download(url, params, filename) {
    m.warning('文件传输中')
    return ANIMAL_REQUEST.get(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      // 字符内容转变成blob地址
      const blob = new Blob([content])
      //判断属性属于对象    download属性兼容性不好
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        //Internet Explorer 10 的  msSaveBlob 和 msSaveOrOpenBlob 方法
        // 允许用户在客户端上保存文件
        navigator.msSaveBlob(blob, filename)
      }
    }).catch((r) => {
      console.error(r)
      m.error('下载失败')
    })
  },
  export(url, params = {}) {
    m.warning('导出数据中')
    return ANIMAL_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      // 字符内容转变成blob地址
      const blob = new Blob([content])
      const fileName = `${new Date().getTime()}_导出.xlsx`
      //判断属性属于对象    download属性兼容性不好
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        //Internet Explorer 10 的  msSaveBlob 和 msSaveOrOpenBlob 方法
        // 允许用户在客户端上保存文件
        navigator.msSaveBlob(blob, fileName)
      }
    }).catch(() => {

      m.error('导出失败')
    })
  }
}


export default request








