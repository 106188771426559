import Vue from 'vue'
import VueRouter from 'vue-router'
import db from '../api/localstorage.js'
import request from '../api/request'

Vue.use(VueRouter)
//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}


const routes = [
  {
    path: '/',
    redirect: {
      name: 'welcome',
    }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: _ => import('../views/login/welcome.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: _ => import('../views/login/login.vue')
  }, {
    path: '/forget',
    name: 'forget',
    component: _ => import('../views/login/forget.vue')
  }, {
    path: '/regist',
    name: 'regist',
    component: _ => import('../views/login/regist.vue')
  },
  {
    path: '/sys',
    name: 'sys',
    component: _ => import('../views/sys.vue'),
    redirect: '/default',
    children: [
      {
        path: '/default',
        component: _ => import('../views/default'),
      },
      // 添加.编辑实验
      {
        path: '/sys/addTask',
        name: 'addTask',
        meta: {closeable: true, jumpPath: '/sys/taskList'},
        component: _ => import('../components/experiment/productionTask/addTask.vue'),
      },
      // 实验详情
      {
        path: '/sys/taskDetail',
        name: 'taskDetail',
        meta: {closeable: true, jumpPath: '/sys/taskList'},
        component: _ => import('../components/experiment/productionTask/taskDetail.vue'),
      },
      // 采购确认
      {
        path: '/sys/proDetail',
        name: 'proDetail',
        meta: {closeable: true, jumpPath: '/sys/procurement'},
        component: _ => import('../components/experiment/procurement/proDetail.vue'),
      },
      // 检疫确认
      {
        path: '/sys/quarantineDetail',
        name: 'quarantineDetail',
        meta: {closeable: true, jumpPath: '/sys/quarantineTab'},
        component: _ => import('../components/experiment/quarantine/quarantineDetail.vue'),
      },
      // 审批详情
      {
        path: '/sys/approvalDetail',
        name: 'approvalDetail',
        meta: {closeable: true, jumpPath: '/sys/approvalList'},
        component: _ => import('../components/experiment/approval/approvalDetail.vue'),
      },
      // 笼舍详情
      {
        path: '/sys/cuddlesDetail',
        name: 'cuddlesDetail',
        meta: {closeable: true, jumpPath: '/sys/cuddles'},
        component: _ => import('../components/experiment/cuddles/cuddlesDetail.vue'),
      },
      // 新增实验
      {
        path: '/sys/newExper',
        name: 'newExper',
        meta: {closeable: true, jumpPath: '/sys/experiment'},
        component: _ => import('../components/experiment/exper/newExper.vue'),
      },
      // 实验详情
      {
        path: '/sys/detail',
        name: 'detail',
        meta: {closeable: true, jumpPath: '/sys/experiment'},
        component: _ => import('../components/experiment/exper/detail.vue'),
      },
      /*   //动物实验
         ...system,
         // 日常管理
         ...daily,
         // 系统管理
         ...sysMana,*/
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})
const whiteList = ['/login', '/forget', '/welcome', '/regist']

let asyncRouter


//导航守卫，渲染动态路由
router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    if (whiteList.indexOf('/login') !== -1 && to.query.openid) {
      localStorage.setItem("openId", to.query.openid)
    }
    next();
  } else {

    let token = db.get('USER_TOKEN')
    let user = db.get('USER')
    let userRouter = get('USER_ROUTER')


    if (token.length && user) {
      if (!asyncRouter) {

        if (!userRouter) {
          request.get(`oauth/menu/${user.username}`).then((res) => {
            asyncRouter = res.data
            save('USER_ROUTER', asyncRouter)
            go(to, next)
          })
        } else {
          asyncRouter = userRouter
          go(to, next)
        }
      } else {
        //save('USER_ROUTER', asyncRouter)
        next()
      }
    } else {
      next('/welcome');
    }
  }
})

function get(name) {
  return (localStorage.getItem(name) == undefined || localStorage.getItem(name) == 'undefined') ? null : JSON.parse(localStorage.getItem(name))
}

function save(name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

function go(to, next) {

  asyncRouter = formatRoutes(asyncRouter)

  router.addRoutes(asyncRouter)
  next({...to, replace: true})
  // replace 一个布尔类型，默认为false。如果replace设置为true，
  // 那么导航不会留下history记录，点击浏览器回退按钮不会再回到这个路由。
}

const formatRoutes = (routes) => {
  let fmRoutes = [];
  routes.forEach(router => {
    let {
      path,
      component,
      name,
      children,
      meta,
      icon
    } = router;
    if (children && children instanceof Array) {
      children = formatRoutes(children);
    }
    let fmRouter = {
      path: path,
      component(resolve) {
        if (component && Object.is(component, 'sys')) {    /*component.startsWith("sys")*/
          import('@/views' + '/' + component + '.vue').then(mod => {
            resolve(mod)
          })
        } else {
          import('@/components' + '/' + component + '.vue').then(mod => {
            resolve(mod)
          })
        }
      },
      name: name,
      children: children,
      meta: meta,
      icon: icon,
    };
    fmRoutes.push(fmRouter);
  })
  return fmRoutes;
}
export default router
