import local from '../../api/localstorage'

export default {
//vuex中的store分模块管理，需要在store的index.js中引入各个模块，
// 为了解决不同模块命名冲突的问题，将不同模块的namespaced:true，
// 之后在不同页面中引入getter、actions、mutations时，需要加上所属的模块名
    namespaced: true,
    state:{
        token: local.get('USER_TOKEN'),
        expireTime: local.get('EXPIRE_TIME'),
        user:local.get('USER'),
        permissions: local.get('PERMISSIONS'),
        roles: local.get('ROLES'),
        positions: local.get('POSITIONS'),
        keepAlive:['addPro'],
        infoTab: local.get('INFO_TAB'),
    },
    mutations:{
        setInfoTab(state,val){
            local.save('INFO_TAB',val)
            state.infoTab =val
        },
        setToken(state,val){
            local.save('USER_TOKEN',val)
            state.token =val
        },
        setExpireTime(state,val){
            local.save('EXPIRE_TIME',val)
            state.expireTime =val
        },
        setUser(state,val){
            local.save('USER',val)
            state.user =val
        },
        setPermissions(state,val){
            local.save('PERMISSIONS',val)
            state.permissions = val
        },
        setRoles(state,val){
            local.save('ROLES',val)
            state.roles =val
        },
        setPositions(state,val){
            local.save('POSITIONS',val)
            state.positions =val
        },
        setKeepAlive(state,keepAlive){
            state.keepAlive = keepAlive
        },
    },
}
