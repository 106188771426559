import '@babel/polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './api/request'
import local from './api/localstorage'
import qs from 'qs'
import * as filters from './api/timezone'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import {
  Radio, RadioGroup, Form, FormItem, Input, Button, ButtonGroup, Link,
  Container, Header, Aside, Main, Footer, Avatar, Dropdown,
  DropdownMenu, Message, Menu, Table, TableColumn, Submenu,
  Select, Option, OptionGroup, MenuItem, MenuItemGroup,
  DropdownItem, DatePicker, Pagination, Tag, Dialog, Timeline,
  TimelineItem, Upload, Tabs, TabPane, Steps, Step, MessageBox,
  Checkbox, CheckboxGroup, CheckboxButton, Calendar, Autocomplete,
  Drawer, Tree, Switch, Tooltip, Popover, Notification, ColorPicker
} from 'element-ui'

import 'ant-design-vue/dist/antd.css';
import {TreeSelect} from 'ant-design-vue'



Vue.use(Radio).use(RadioGroup).use(Form).use(FormItem).use(Input).use(Button).use(ButtonGroup).use(Link)
    .use(Container).use(Header).use(Aside).use(Main).use(Footer).use(Avatar).use(Dropdown)
    .use(DropdownMenu).use(DropdownItem).use(Menu).use(Submenu).use(MenuItem).use(MenuItemGroup)
    .use(Table).use(TableColumn).use(Select).use(Option).use(OptionGroup).use(DatePicker)
    .use(Pagination).use(Tag).use(Dialog).use(Timeline).use(TimelineItem).use(Upload).use(Tabs)
    .use(TabPane).use(Steps).use(Step).use(Checkbox).use(CheckboxButton).use(CheckboxGroup)
    .use(Calendar).use(Autocomplete).use(Drawer).use(TreeSelect).use(Tree).use(Switch).use(Tooltip).use(Popover)
    .use(ColorPicker)


import './assets/css/common.scss';

Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$postJson = request.postJson;
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$put = request.put;
Vue.prototype.$putJson = request.putJson;
Vue.prototype.$del = request.delete;
Vue.prototype.$export = request.export;
Vue.prototype.$download = request.download;
Vue.prototype.$upload = request.upload;
Vue.prototype.$local = local;
Vue.prototype.$qs = qs;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
